import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import InstaDetails from "../components/InstaDetails/InstaDetails";
import PaymentForm from "../components/PaymentForm/PaymentForm";
import { navigate } from "gatsby";
import { UserContext } from "../components/Context";
import axios from "axios";
import PaymentNavbar from "../components/PaymentNavbar/PaymentNavbar";
import Seo from "../components/Seo";

const Details = styled.div`
  /* max-width: 794px;
  width: 100%; */
  /* padding: 66px; */
  width: 100%;
  // background: #f9fdff;
  // padding: 28px 46px;
  // border: 0.5px solid #e6e6e6;
  // border-radius: 10px;
  max-width: 759px;

  @media (max-width: 375px) {
    // padding: 28px 9px;
  }
`;
const InnerDiv = styled.div`
  /* width: 794px; */

  padding: 20px 66px 30px 66px;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.0125em;
    color: #676767;
    margin-bottom: 5px !important;
    text-align: center;
    a {
      text-decoration: none;
    }
  }
  @media (max-width: 767px) {
    padding: 12px;
  }
`;
const Heading = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 0.0125em;
  color: #3d3d3d;
  // border-bottom: 0.5px solid #c6c6c6;
  // margin: 32px 0px;
  @media (max-width: 575px) {
    font-size: 18px;
    // margin: 0px 0px 10px 0px;
  }
`;
const Circle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  text-align: center;
  /* border: 1px dashed #1da1f2; */
  background-color: #1da1f2;
  color: #ffffff;
  &:nth-child(3) {
    /* border: 2px dashed #1da1f2; */
    background-color: #1da1f2;
    color: #fff;
  }
  &:nth-child(5) {
    border: 2px dashed #1da1f2;
    background-color: #1da1f2;
    color: #fff;
  }
`;
const Linediv = styled.div`
  width: 56px;
  height: 2px;
  background: #1da1f2;
  &:nth-child(4) {
    border: 1px solid #1da1f2;
    background-color: #1da1f2;
    color: #fff;
  }
`;
const OrderDetail = styled.div`
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const OrderHeading = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  /* identical to box height */

  letter-spacing: 0.0125em;
  text-transform: uppercase;

  /* Primary/text */

  color: #222831;
`;

const OrderText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.0125em;

  color: #000000;
`;

const ConfirmationText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #676767;
`;
const HomeBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 252px 16px 242px;
  gap: 8px;
  color: white;
  border: none;
  font-family: "Poppins";
  background: #1da1f2;
  border-radius: 10px;
  :hover {
    opacity: 1;
    transition-duration: 0.6s;
    background: rgb(131 91 199);
  }
`;

const ThankYou = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orderRes, setOrderRes] = useState([]);
  const { response } = useContext(UserContext) as any;



  const fetchOrderStatus = async () => {
    try {
      setIsLoading(true);
      const config = {
        method: "post",
        url: `${process.env.BACKEND_URL}/api/check-order`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {orderId:[response.storeOrderData.orderId]}
      };
      const { data } = await axios(config);


      setOrderRes(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {

   
    if (Object.keys(response).length !== 0) {
      fetchOrderStatus();
    }
  }, [response]);

  return (
    <div className="mb-5">
      <Seo title="Order Status" description="Order Status page" />
      <PaymentNavbar steps="Final" />
      <div className="d-flex flex-column align-items-center px-2 px-md-0">
        <Details className=" d-flex justify-content-center align-items-center mt-5"></Details>
        <>
          {orderRes.length !== 0 ? (
            <InnerDiv className="w-50 ">
              <Heading className="text-center">Thank you</Heading>
              <hr />

              {orderRes.map((item: any, index: number) => {
                return (
                  <OrderDetail key={index}>
                    <div className="col-3 d-flex flex-column  justify-content-center align-items-center my-3">
                      <OrderHeading>ORDER ID</OrderHeading>
                      <OrderText>{item.orderId}</OrderText>
                    </div>
                    <div className="col-3 d-flex flex-column  justify-content-center align-items-center my-3">
                      <OrderHeading>Status</OrderHeading>
                      <OrderText>{item.status}</OrderText>
                    </div>
                    <div className="col-3 d-flex flex-column  justify-content-center align-items-center my-3">
                      <OrderHeading>Start Count</OrderHeading>
                      <OrderText>{item.start_count}</OrderText>
                    </div>
                    <div className="col-3 d-flex flex-column  justify-content-center align-items-center my-3">
                      <OrderHeading>Remains</OrderHeading>
                      <OrderText>{item.remains}</OrderText>
                    </div>
                  </OrderDetail>
                );
              })}
              {/* <ConfirmationText className="text-center mt-3">
  We have sent all the details to your{" "}
  <span style={{ fontWeight: 700 }}> mail ID</span> please check{" "}
</ConfirmationText> */}
              <HomeBtn
                onClick={() => {
                  navigate("/");
                }}
                className="mt-4 w-100"
              >
                Go to Homepage
              </HomeBtn>
            </InnerDiv>
          ) : isLoading ? (
            <Heading className="text-center"> Loading ...</Heading>
          ) : (
            <Heading className="text-center">Something went wrong</Heading>
          )}
        </>
      </div>
    </div>
  );
};

export default ThankYou;